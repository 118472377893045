<template>
  <b-container v-if="order.shopInformation">
    <b-row>
      <!--- card sebelah kiri -->
      <b-col lg="4" sm="12" md="4">
        <!--- card order info -->
        <b-row>
          <b-col>
            <b-card style="zoom: 85%" class="m-0 p-0">
              <b-card-body class="m-0 p-0">
                <b-table-simple
                  :items="order.shopInformation"
                  :busy="isBusy"
                  borderless
                >
                  <b-tbody>
                    <b-tr>
                      <b-td>
                        <b>Marketplace</b>
                      </b-td>
                      <b-td>
                        <b-img
                          v-if="
                            order.shopInformation.marketplaceType ===
                            'tokopedia'
                          "
                          :src="
                            require('@/assets/images/marketplace/tokopedia.png')
                          "
                          class="img-fluid icon-marketplace"
                          width="16"
                          height="16"
                        />
                        <span
                          v-if="
                            order.shopInformation.marketplaceType ===
                            'tokopedia'
                          "
                        >
                          Tokopedia
                        </span>
                        <b-img
                          v-if="
                            order.shopInformation.marketplaceType === 'shopee'
                          "
                          :src="
                            require('@/assets/images/marketplace/shopee.png')
                          "
                          class="img-fluid icon-marketplace"
                          width="16"
                          height="16"
                        />
                        <span
                          v-if="
                            order.shopInformation.marketplaceType === 'shopee'
                          "
                        >
                          Shopee
                        </span>
                        <b-img
                          v-if="
                            order.shopInformation.marketplaceType === 'lazada'
                          "
                          :src="
                            require('@/assets/images/marketplace/lazada.png')
                          "
                          class="img-fluid icon-marketplace"
                          width="16"
                          height="16"
                        />
                        <span
                          v-if="
                            order.shopInformation.marketplaceType === 'lazada'
                          "
                        >
                          Lazada
                        </span>
                        <b-img
                          v-if="
                            order.shopInformation.marketplaceType === 'tiktok'
                          "
                          :src="
                            require('@/assets/images/marketplace/tiktok.png')
                          "
                          class="img-fluid icon-marketplace"
                          width="16"
                          height="16"
                        />
                        <span
                          v-if="
                            order.shopInformation.marketplaceType === 'tiktok'
                          "
                        >
                          Tiktok
                        </span>
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <b>Toko</b>
                      </b-td>
                      <b-td>
                        {{ order.shopInformation.marketplaceName }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <b>Kode Pemesanan</b>
                      </b-td>
                      <b-td>
                        {{ order.shopInformation.orderId }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <b>Tgl. Pemesanan</b>
                      </b-td>
                      <b-td>
                        {{ order.shopInformation.createdDate }}
                      </b-td>
                    </b-tr>
                    <b-tr>
                      <b-td>
                        <b>Nama Penerima</b>
                      </b-td>
                      <b-td>
                        {{ order.shopInformation.recipientName }}
                      </b-td>
                    </b-tr>
                  </b-tbody>
                </b-table-simple>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
        <!-- end card order info -->

        <!--- card order shipment info -->
        <b-row class="pt-2">
          <b-col>
            <b-card style="zoom: 85%">
              <b-table-simple :items="order.items" borderless>
                <b-tbody>
                  <b-tr>
                    <b-td><b>Provinsi</b></b-td>
                    <b-td>order.shopInformation.receipent_province</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><b>Kota</b></b-td>
                    <b-td>order.shopInformation.receipent_city</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td><b>Kecamatan</b></b-td>
                    <b-td>order.shopInformation.receipent_district</b-td>
                  </b-tr>
                  <b-tr v-if="order.shopInformation.receipent_postal_code">
                    <b-td><b>Kode Pos</b></b-td>
                    <b-td>order.shopInformation.receipent_postal_code</b-td>
                  </b-tr>
                  <b-tr style="border-top: 0.5px solid #ccc">
                    <b-td><b>Alamat Pembeli</b></b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="2">
                      order.shopInformation.receipent_address
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-card>
          </b-col>
        </b-row>
        <!-- end card order shipment info -->

        <!--- card order geo info -->
        <b-row v-if="order.shopInformation.receipent_geo">
          <b-col>
            <b-card>
              <div class="mapouter">
                <div class="gmap_canvas">
                  <iframe
                    id="gmap_canvas"
                    width="300"
                    height="300"
                    :src="`https://maps.google.com/maps?q=${order.shopInformation.receipent_geo}&t=&z=15&ie=UTF8&iwloc=addr&iwd=0&layer=0&om=0&output=embed`"
                    frameborder="0"
                    scrolling="no"
                    marginheight="0"
                    marginwidth="0"
                  />
                </div>
              </div>
            </b-card>
          </b-col>
        </b-row>
        <!-- end card order geo info -->
      </b-col>

      <!--- card sebelah kanan -->
      <b-col lg="8" sm="12" md="8">
        <b-row>
          <!--- data produk -->
          <b-col>
            <b-card title="Detail Produk">
              <b-table
                :fields="orderTableField"
                responsive
                borderless
                small
                :items="order.dataProduct"
                :busy="isBusy"
              >
                <!-- Name Produk -->
                <template #cell(productName)="data">
                  <div class="text-left">
                    <span>{{ data.item.productName }}</span>
                    <p v-if="data.item.notes">
                      <small class="text-muted"
                        ><em>{{ data.item.notes }}</em></small
                      >
                    </p>
                    <div
                      v-if="order.shopInformation.marketplaceType === 'lazada'"
                    >
                      <ul class="text-primary list-unstyled">
                        <li style="font-size: 0.8em">
                          Order Item ID: {{ data.item.order_item_id }}
                        </li>
                        <li style="font-size: 0.8em">
                          SKU Toko: {{ data.item.sku }}
                        </li>
                      </ul>
                    </div>
                    <p v-if="!data.item.notes">
                      <small class="text-muted"
                        ><em>Tidak ada catatan</em></small
                      >
                    </p>
                  </div>
                </template>
                <!-- price -->
                <template #cell(price)="data">
                  <div class="text-right">
                    <span>{{ data.item.price }}</span>
                  </div>
                </template>

                <!-- subtotal -->
                <!-- <template #cell(subtotal)="data">
                  <div class="text-right align-item-center">
                    <span>{{ data.item.total_price }}</span>
                  </div>
                </template> -->

                <!-- isBusy -->
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle" />
                    <strong> Memuat Data...</strong>
                  </div>
                </template>
              </b-table>
            </b-card>
          </b-col>
          <!-- end data produk -->
        </b-row>

        <b-row v-if="order.shopInformation.marketplaceType === 'tokopedia'">
          <b-col>
            <b-card title="Order Timeline">
              <app-timeline v-if="order.tokopediaOrderData.length > 0">
                <app-timeline-item
                  v-for="(data, index) in order.tokopediaOrderData"
                  :key="index"
                  :title="data.hist_status_code.toString()"
                  :subtitle="data.update_by.toString()"
                  :time="data.timestamp.toString()"
                />
              </app-timeline>
              <p v-else class="text-center">
                Tidak ada masa lalu disini mari buka lembaran yang baru
              </p>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  computed,
  onMounted,
} from '@vue/composition-api';
import {
  BCard,
  BCardBody,
  BTableSimple,
  BTbody,
  BTr,
  BTd,
  BRow,
  BCol,
  BContainer,
  BTable,
  BSpinner,
  BImg,
  BListGroup,
  BListGroupItem,
} from 'bootstrap-vue';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
// eslint-disable-next-line import/no-cycle
// import { useRouter } from '@core/utils/utils'

import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import moment from 'moment';

export default defineComponent({
  components: {
    BCard,
    BCardBody,
    BTableSimple,
    BTbody,
    BRow,
    BCol,
    BTr,
    BTd,
    BContainer,
    BTable,
    BSpinner,
    BImg,
    AppTimeline,
    AppTimelineItem,
    BListGroup,
    BListGroupItem,
  },
  setup() {
    const rupiahFormat = value => {
      const rupiah = new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      });
      return rupiah.format(value);
    };
    // const router = root.$router
    // const { route } = useRouter()

    const isBusy = ref(false);

    const orderTableField = reactive([
      {
        key: 'productName',
        label: 'Nama Produk',
      },
      {
        key: 'price',
        label: 'Harga',
        class: 'text-center',
      },
      {
        key: 'sku',
        label: 'SKU',
        class: 'text-center',
      },
      // {
      //   key: 'subtotal',
      //   label: 'Subtotal',
      //   class: 'text-center',
      // },
    ]);

    const order = reactive({
      shopInformation: computed(() => {
        isBusy.value = false;
        return store.state.orders.order;
      }),

      tokopediaOrderData: reactive([]),

      dataProduct: computed(() => {
        isBusy.value = false;
        return [...store.state.orders.order.productList];
      }),
    });

    // const getData = async () => {
    //   // fecth data from store
    //   const { id } = route.value.params
    //   isBusy.value = true
    //   store.dispatch('orders/getSingleOrder', id)
    // }

    onMounted(() => {
      // console.log('mounted', store.state.orders.order)
      // getData()
    });

    // watch store.state.orders.order
    store.subscribe((mutation, state) => {
      if (order.shopInformation.marketplace === 'tokopedia') {
        if (mutation.type === 'orders/SET_SINGLE_ORDER_DATA') {
          store
            .dispatch('tokopedia/getOrderSingle', {
              order_id: order.shopInformation.order_id,
            })
            .then(() => {
              const data =
                state.tokopedia.order_single.order_info.order_history;

              data.forEach(item => {
                item.timestamp = moment(item.timestamp).format(
                  'DD MMM YYYY HH:mm',
                );
                switch (item.hist_status_code) {
                  case 500:
                    item.hist_status_code = 'Pesanan Dikirim';
                    break;
                  case 501:
                    item.hist_status_code = 'Menunggu Input Resi';
                    break;
                  case 400:
                    item.hist_status_code = 'Penjual Menerima Pesanan';
                    break;
                  case 450:
                    item.hist_status_code = 'Menunggu Pickup';
                    break;
                  case 220:
                    item.hist_status_code = 'Pembayaran dikonfirmasi';
                    break;
                  case 100:
                    item.hist_status_code = 'Pesanan dibuat';
                    break;
                  case 103:
                    item.hist_status_code = 'Menunggu Konfirmasi Pembayaran';
                    break;
                  case 700:
                    item.hist_status_code = 'Pesanan Selesai';
                    break;
                  case 600:
                    item.hist_status_code = 'Pesanan Terkirim';
                    break;
                  case 690:
                    item.hist_status_code = '[SISTEM] Fraud Review';
                    break;
                  case 190:
                    item.hist_status_code = '[SISTEM] Fraud Detection';
                    break;
                  case 15:
                    item.hist_status_code = 'Pesanan Dibatalkan Oleh Pembeli';
                    break;
                  case 10:
                    item.hist_status_code = 'Pesanan Dibatalkan Oleh Penjual';
                    break;
                  default:
                    break;
                }
              });

              order.tokopediaOrderData = computed(() => data);
            });
        }
      }
    });

    return {
      orderTableField,
      isBusy,
      rupiahFormat,
      // getData,
      order,
    };
  },
});
</script>

<style scoped>
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  height: 300px;
  width: 300px;
}
</style>
